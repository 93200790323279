<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import { ref } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const { headlineText, toplineText, headlineType, toplineType, Slides } =
  props.content?.data;

const settings = {
  itemsToShow: 4,
  itemsToScroll: 4,
  wrapAround: false,
  snapAlign: "start",
  autoplay: 5000,
  transition: 600,
  pauseAutoplayOnHover: true,
  breakpoints: {
    // 200px and up
    200: {
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: "center",
    },
    // 540 and up
    540: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    600: {
      itemsToShow: 3,
      itemsToScroll: 3,
      snapAlign: "start",
    },
    900: {
      itemsToShow: 4,
      itemsToScroll: 4,
      snapAlign: "start",
    },
  },
};

const categorySlider = ref(null);
const currentSlide = ref(0);

const slideTo = (val: any) => {
  currentSlide.value = val;
};

const enableSlider = ref(true);
if (Slides?.length < settings.itemsToShow) {
  enableSlider.value = false;
}

const itemsToShow = computed(() => {
  return categorySlider?.value?.data?.config?.itemsToShow;
});

const showNavigation = computed(() => {
  return itemsToShow.value < Slides.length;
});
</script>
<template>
  <section class="relative">
    <div class="mb-10">
      <div class="container px-0 xl:px-5">
        <SharedTopline
          :headline="toplineText"
          :headlineType="toplineType"
        ></SharedTopline>
        <SharedHeadline
          :headline="headlineText"
          :headlineType="headlineType"
          class=""
        ></SharedHeadline>
      </div>
    </div>

    <div class="">
      <div class="container max-w-[90rem] px-2 laptop:px-10">
        <div
          id="CategoriesCarousel"
          class="-mx-2.5 px-3.5 laptop:px-0 text-center"
        >
          <Carousel
            v-if="enableSlider"
            v-bind="settings"
            ref="categorySlider"
            v-model="currentSlide"
          >
            <Slide v-for="slide of Slides" :key="slide">
              <SharedLink :button="slide.sliderLink" class="w-full">
                <template #link>
                  <div class="slide text-center px-0 sm:px-2.5 w-full group">
                    <div
                      class="h-[21.25rem] bg-skin-theme text-skin-white flex items-center justify-center rounded relative overflow-hidden hover:bg-black hover:bg-opacity-10"
                    >
                      <!-- Content Wrapper -->
                      <div
                        class="w-full h-full relative flex px-5 flex-col items-center justify-center"
                      >
                        <!-- Icon Section -->
                        <div
                          class="icon-wrapper h-[40px] w-[40px] justify-center items-center prose-img:h-full transform scale-100 transition-all duration-700 ease-[cubic-bezier(0.64, 0.57, 0.67, 1.53)] group-hover:scale-[3] group-hover:opacity-50 group-hover:translate-x-[7rem] group-hover:translate-y-[9rem]"
                        >
                          <NuxtImg
                            v-if="
                              slide.sliderHoverImage &&
                              slide.sliderHoverImage.media
                            "
                            loading="lazy"
                            format="webp"
                            :src="slide.sliderHoverImage.media.url"
                            alt="icon"
                            height="slide.sliderHoverImage.media.metadata.height"
                            width="slide.sliderHoverImage.media.metadata.width"
                            class="transition-all duration-800 ease-in-out max-w-[40px]"
                          />
                        </div>

                        <!-- Title Section -->
                        <h3
                          class="font-bold mt-2 text-2xl transform translate-y-0 scale-100 transition-all duration-700 ease-[cubic-bezier(0.64, 0.57, 0.67, 1.53)] group-hover:translate-y-[7rem]"
                        >
                          {{ slide.sliderTitle }}
                        </h3>
                      </div>

                      <!-- Background Image -->
                      <NuxtImg
                        class="absolute -z-[2] w-full h-full object-cover top-0 left-0"
                        v-if="slide.sliderImage && slide.sliderImage.media"
                        loading="lazy"
                        format="webp"
                        densities="x1"
                        width="325"
                        :src="slide.sliderImage.media.url"
                      />
                      <div
                        class="bg-[linear-gradient(to_top_right,_#00000087_10%,_transparent_90%)] absolute w-full h-full -z-[1] transition-opacity group-hover:opacity-0 opacity-100 ease-in-out duration-600"
                      ></div>
                    </div>
                  </div>
                </template>
              </SharedLink>
            </Slide>
            <template v-if="showNavigation" #addons>
              <div
                class="carousel__navigation-button z-0 absolute top-2/4 md:top-2/4 max-w-[90rem] flex justify-between left-[-30px] right-[-30px] sm:left-[-25px] sm:right-[-25px] mx-auto [&>button]:static"
              >
                <Navigation>
                  <template #next>
                    <img
                      src="assets/icons/slide-right.svg"
                      alt="Next"
                      class="w-6 h-6"
                    />
                  </template>
                  <template #prev>
                    <img
                      src="assets/icons/slide-left.svg"
                      alt="Previous"
                      class="w-6 h-6"
                    />
                  </template>
                </Navigation>
              </div>
            </template>
          </Carousel>
          <div
            v-else
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 -mx-2.5 px-2.5 mt-10"
          >
            <div
              v-for="slide of Slides"
              :key="slide"
              class="slide text-center px-0 sm:px-2.5 w-full group"
            >
              <SharedLink :button="slide.sliderLink" class="w-full">
                <template #link>
                  <div
                    class="h-[21.25rem] bg-skin-theme text-skin-white flex items-center justify-center rounded relative overflow-hidden hover:bg-black hover:bg-opacity-10"
                  >
                    <!-- Content Wrapper -->
                    <div
                      class="w-full h-full relative flex px-5 flex-col items-center justify-center z-10"
                    >
                      <!-- Icon Section -->
                      <div
                        class="icon-wrapper h-[40px] w-[40px] justify-center items-center prose-img:h-full transform scale-100 transition-all duration-700 ease-[cubic-bezier(0.64, 0.57, 0.67, 1.53)] group-hover:scale-[3] group-hover:opacity-50 group-hover:translate-x-[7rem] group-hover:translate-y-[9rem] relative z-20"
                      >
                        <NuxtImg
                          v-if="
                            slide.sliderHoverImage &&
                            slide.sliderHoverImage.media
                          "
                          loading="lazy"
                          format="webp"
                          :src="slide.sliderHoverImage.media.url"
                          alt="icon"
                          :height="slide.sliderHoverImage.media.metadata.height"
                          :width="slide.sliderHoverImage.media.metadata.width"
                          class="transition-all duration-800 ease-in-out max-w-[40px] h-auto"
                        />
                      </div>

                      <!-- Title Section -->
                      <h3
                        class="font-bold mt-2 text-2xl transform translate-y-0 scale-100 transition-all duration-700 ease-[cubic-bezier(0.64, 0.57, 0.67, 1.53)] group-hover:translate-y-[7rem] relative z-20"
                      >
                        {{ slide.sliderTitle }}
                      </h3>
                    </div>

                    <!-- Background Image -->
                    <NuxtImg
                      class="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100"
                      v-if="slide.sliderImage && slide.sliderImage.media"
                      loading="lazy"
                      format="webp"
                      density="1"
                      width="325"
                      :src="slide.sliderImage.media.url"
                    />
                    <div
                      class="bg-[linear-gradient(to_top_right,_#00000087_10%,_transparent_90%)] absolute w-full h-full -z-[1] transition-opacity group-hover:opacity-0 opacity-100 ease-in-out duration-600"
                    ></div>
                  </div>
                </template>
              </SharedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="/images/layer2.svg"
      alt=""
      class="absolute right-4 -top-16 hidden lg:block pointer-events-none"
    />
  </section>
</template>

<style scoped>
.carousel__navigation-button :deep(.carousel__prev),
.carousel__navigation-button :deep(.carousel__next) {
  @apply bg-skin-icon text-skin-white w-11 h-11 rounded-full flex items-center justify-center;
  pointer-events: all;
}

.carousel__navigation-button {
  z-index: 0;
  pointer-events: none;
}
</style>
